<i18n>
{
  "de": {
    "availability_TRUE": "Verfügbare Messdaten",
    "availability_FALSE": "Hochrechnung",
    "yLabels": {
      "amount": "Endenergie (MWh)",
      "amount_normalized": "Endenergie witterungsbereinigt (MWh)",
      "ee": "Endenergie (MWh)",
      "s12e": "Scope 1-2 Emissionen (t)",
      "s123e": "Scope 1-3 Emissionen (t)",
      "per": "Primärenergie (erneuerbar) (MWh)"
    },
    "units": {
      "amount": "MWh",
      "amount_normalized": "MWh",
      "s12e": "t",
      "s123e": "t",
      "per": "MWh",
      "ee": "MWh"
    }
  }
}
</i18n>

<template>
  <AsyncContentContainer :content-state="contentState" class="measured-kpi-chart">
    <template #default>
      <BarChart :chart-data="chartData" :options="options" exportable />
    </template>
  </AsyncContentContainer>
</template>

<script>
import AsyncContentMixin from '@/components/shared/AsyncContentMixin.vue'
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer.vue'
import BarChart from '@/components/shared/charts/BarChart.vue'

import compassApi from '@/services/compass-api.js'
import colorPalettes from '@/services/color-palettes.js'
import { mapGetters } from 'vuex'

export default {
  mixins: [
    //
    AsyncContentMixin,
    ChartOptionsMixin,
  ],

  components: {
    BarChart,
    AsyncContentContainer,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
    },
    kpiType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      timeseries: null,
    }
  },

  computed: {
    ...mapGetters({
      __getEnhancedSustainabilityIndicator: 'portfolio/getEnhancedSustainabilityIndicatorByIdentifier',
    }),

    sustainabilityIndicator() {
      return this.__getEnhancedSustainabilityIndicator(this.kpiType)
    },

    yLabel() {
      if (this.getIndicatorName === this.kpiType) {
        return this.$t(`yLabels.${this.kpiType}`)
      }
      return `${this.getIndicatorName} (${this.bigUnit})`
    },

    getIndicatorName() {
      if (!this.sustainabilityIndicator) {
        return this.kpiType
      }
      return this.sustainabilityIndicator.name
    },

    bigUnit() {
      if (this.sustainabilityIndicator === undefined) {
        return this.kpiType
      }
      if (this.sustainabilityIndicator === null) {
        return this.$t(`units.${this.kpiType}`)
      }
      return this.sustainabilityIndicator.getBigTargetUnit()
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxis: {
            beginAtZero: true,
            ticks: {
              callback: (value) => this.formatNumber(value),
            },
            title: {
              display: true,
              text: this.yLabel,
            },
          },
        },
        interaction: {
          mode: 'x',
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (items) =>
                this.getTooltipTitleWithTotal(items, {
                  precision: 1,
                  title: this.timeseries.years[items[0].dataIndex],
                  unit: this.bigUnit,
                }),
              label: (item) =>
                this.getTooltipLabelWithPercentage(item, { unit: this.bigUnit, getTotalFn: this.getTotal }),
            },
          },
          legend: {
            position: 'bottom',
            align: 'start',
            reverse: true,
          },
        },
      }
    },

    chartData() {
      const sortOrder = ['END_ENERGY_HEATER', 'ELECTRICITY_TENANTS', 'ELECTRICITY_GENERAL', 'ELECTRICITY_PV']
      let datasets = []
      for (const meterType in this.timeseries.timeseries) {
        if (Object.prototype.hasOwnProperty.call(this.timeseries.timeseries, meterType)) {
          datasets.push({
            meterType: meterType,
            label: this.$t(`_meterTypes.${meterType}`),
            backgroundColor: colorPalettes.meterTypeColors[meterType],
            data: this.timeseries.timeseries[meterType].value.map((v, i) => v / 1000),
            stack: 'Measured',
          })
        }
      }

      datasets.push({
        label: this.$t('availability_TRUE'),
        backgroundColor: 'hsl(230, 20%, 80%)',
        data: this.timeseries.total_available.map((v, i) => v / 1000),
        stack: 'Availability',
      })
      datasets.push({
        label: this.$t('availability_FALSE'),
        backgroundColor: 'hsl(230, 20%, 90%)',
        data: this.timeseries.total_filled.map((v, i) => v / 1000),
        stack: 'Availability',
      })

      datasets.sort((a, b) => sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type))
      return {
        labels: this.timeseries.years,
        datasets: datasets,
      }
    },
  },

  watch: {
    portfolio() {
      this.refreshContent()
    },
    query() {
      this.refreshContent()
    },
  },

  methods: {
    async loadContentHandler(signal) {
      const query = JSON.stringify(this.query)
      this.timeseries = await compassApi.call(
        `/projections/${this.portfolio.id}/measured/${this.kpiType}?query=${query}`,
        'GET',
        null,
        signal
      )
    },

    getTotal(yearIdx) {
      return this.chartData.datasets
        .filter((d) => d.stack === 'Measured')
        .reduce((pv, cv) => pv + Math.max(0, cv.data[yearIdx] ? cv.data[yearIdx] : 0), 0)
    },
  },
}
</script>

<style>
.measured-kpi-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
