<i18n>
{
  "de": {
    "targetPath": "Zielpfad",
    "crremApartments": "Zielpfad CRREM 2023 (Mehrfamilienhaus)",
    "crremOffices": "Zielpfad CRREM 2023 (Büro)",
    "bafu": "Zielpfad BAFU PACTA 2022",
    "yLabels": {
      "ee": "Endenergie (kWh/m²)",
      "rh_demand": "Raumwärmebedarf (kWh/m²)",
      "s12e": "Scope 1-2 Emissionen (kg CO₂e/m²)",
      "s123e": "Scope 1-3 Emissionen (kg CO₂e/m²)",
      "per": "Primärenergie (erneuerbar) (kWh/m²)"
    },
    "units": {
      "ee": "kWh/m²",
      "rh_demand": "kWh/m²",
      "s12e": "kg CO₂e/m²",
      "s123e": "kg CO₂e/m²",
      "per": "kWh/m²"
    }
  }
}
</i18n>

<template>
  <AsyncContentContainer :content-state="contentState" class="kpi-compare-scenario-chart">
    <template #default>
      <FaultsContainer :faults="projectionsByScenario.faulty" :portfolio="portfolio" />
      <LineChart :chart-data="chartData" :options="options" exportable />
    </template>
  </AsyncContentContainer>
</template>

<script>
import AsyncContentMixin from '@/components/shared/AsyncContentMixin.vue'
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer.vue'
import FaultsContainer from '@/components/report/charts/FaultsContainer.vue'
import LineChart from '@/components/shared/charts/LineChart.vue'

import compassApi from '@/services/compass-api'
import { mapGetters } from 'vuex'

import crremApartmentsStaticData from '@/data/CRREM_2023_Apartments_StaticData.json'
import crremOfficesStaticData from '@/data/CRREM_2023_Offices_StaticData.json'
import bafuStaticData from '@/data/BAFU_PACTA_2022_StaticData.json'

export default {
  mixins: [
    //
    AsyncContentMixin,
    ChartOptionsMixin,
  ],

  components: {
    LineChart,
    AsyncContentContainer,
    FaultsContainer,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
    },
    kpiType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      projectionsByScenario: null,
      roundPrecision: 1,
    }
  },

  computed: {
    ...mapGetters({
      __getEnhancedSustainabilityIndicator: 'portfolio/getEnhancedSustainabilityIndicatorByIdentifier',
    }),

    targets() {
      const targets = this.portfolio.targets
      const kpiTypes = ['s12e', 's123e', 'ee', 'pe']

      if (!targets || !kpiTypes.includes(this.kpiType)) {
        return []
      }
      // Filter the portfolio targets for the kpiType (e.g. s12e_total) and non-null values (empty values are null, but there can be valid 0 values)
      const filteredTargets = targets.filter((x) => x[`${this.kpiType}_total`] !== null)
      // Map the filtered targets to get the year and value
      return filteredTargets.map((x) => {
        return {
          year: x.year,
          value: x[`${this.kpiType}_total`],
        }
      })
    },

    sustainabilityIndicator() {
      return this.__getEnhancedSustainabilityIndicator(this.kpiType)
    },

    yLabel() {
      if (this.getIndicatorName === this.kpiType) {
        return this.$t(`yLabels.${this.kpiType}`)
      }
      return `${this.getIndicatorName} (${this.specificUnit})`
    },

    getIndicatorName() {
      if (!this.sustainabilityIndicator) {
        return this.kpiType
      }
      return this.sustainabilityIndicator.name
    },

    specificUnit() {
      if (this.sustainabilityIndicator === undefined) {
        return this.kpiType
      }
      if (this.sustainabilityIndicator === null) {
        return this.$t(`units.${this.kpiType}`)
      }
      return this.sustainabilityIndicator.getSpecificTargetUnit()
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        /* aspectRatio: 3, */
        scales: {
          yAxis: {
            beginAtZero: true,
            title: {
              display: true,
              text: this.yLabel,
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (item) =>
                this.getTooltipLabelWithReductionString(item, {
                  precision: this.roundPrecision,
                  unit: this.specificUnit,
                }),
            },
          },
          legend: {
            position: 'bottom',
            align: 'start',
            display: true,
            reverse: true,
          },
        },
      }
    },

    chartData() {
      let datasets = []

      //
      if (this.kpiType === 's123e') {
        datasets.push({
          label: this.$t('crremApartments'),
          borderColor: '#888888',
          pointBackgroundColor: '#ffffff',
          pointHoverBackgroundColor: '#ffffff',
          pointHoverBorderWidth: 2,
          pointHoverRadius: 4,
          pointRadius: 2,
          borderWidth: 3,
          borderDash: [5, 5],
          fill: false,
          lineTension: 0,
          pointHitRadius: 20,
          data: this.getStaticData(crremApartmentsStaticData),
        })
        datasets.push({
          label: this.$t('crremOffices'),
          borderColor: '#bbbbbb',
          pointBackgroundColor: '#ffffff',
          pointHoverBackgroundColor: '#ffffff',
          pointHoverBorderWidth: 2,
          pointHoverRadius: 4,
          pointRadius: 2,
          borderWidth: 3,
          borderDash: [8, 8],
          fill: false,
          lineTension: 0,
          pointHitRadius: 20,
          data: this.getStaticData(crremOfficesStaticData),
        })
      }

      //
      if (this.kpiType === 's1e') {
        datasets.push({
          label: this.$t('bafu'),
          borderColor: '#888888',
          pointBackgroundColor: '#ffffff',
          pointHoverBackgroundColor: '#ffffff',
          pointHoverBorderWidth: 2,
          pointHoverRadius: 4,
          pointRadius: 2,
          borderWidth: 3,
          borderDash: [5, 5],
          fill: false,
          lineTension: 0,
          pointHitRadius: 20,
          data: this.getStaticData(bafuStaticData),
        })
      }

      //
      if (this.targets.length > 0) {
        datasets.push({
          label: this.$t('targetPath'),
          borderColor: 'rgba(0,0,0,0.1)',
          pointBackgroundColor: '#ffffff',
          pointHoverBackgroundColor: '#ffffff',
          pointHoverBorderWidth: 2,
          pointHoverRadius: 4,
          pointRadius: 2,
          borderWidth: 4,
          fill: false,
          lineTension: 0,
          spanGaps: true,
          pointHitRadius: 20,
          data: this.projectionsByScenario.years
            .map((year) => this.targets.find((target) => target['year'] === year))
            .map((x) => (x === undefined ? null : x['value'])),
        })
      }

      //
      for (const scenarioId in this.projectionsByScenario.projections) {
        const scenario = this.portfolio.scenarios.find((s) => s.id === parseInt(scenarioId))
        datasets.unshift({
          label: scenario.description,
          borderColor: scenario.color,
          pointBackgroundColor: '#ffffff',
          pointHoverBackgroundColor: '#ffffff',
          pointHoverBorderWidth: 2,
          pointHoverRadius: 4,
          pointRadius: 2,
          borderWidth: 4,
          fill: false,
          lineTension: 0,
          pointHitRadius: 20,
          data: this.projectionsByScenario.projections[scenarioId].value.map(
            (v, i) => v / this.projectionsByScenario.projections[scenarioId].area[i]
          ),
        })
      }

      return {
        labels: this.projectionsByScenario.years,
        datasets: datasets,
      }
    },
  },

  watch: {
    portfolio() {
      this.refreshContent()
    },
    query() {
      this.refreshContent()
    },
  },

  methods: {
    async loadContentHandler(signal) {
      const query = JSON.stringify(this.query)
      this.projectionsByScenario = await compassApi.call(
        `/projections/${this.portfolio.id}/${this.kpiType}/scenarios?query=${query}`,
        'GET',
        null,
        signal
      )
    },

    getStaticData(source) {
      return this.projectionsByScenario.years.map((year) => source[year])
    },
  },
}
</script>

<style>
.kpi-compare-scenario-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}

.error-container {
  padding: 0 8px 0 55px;
  color: red;
}

.error-container .building-id {
  border-bottom: 1px solid red;
  margin-right: 4px;
}
</style>
